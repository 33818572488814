import styles from './spinner.module.css'

const Wrapper = styled.span(`
  fill-rose-500
  font-bold
  font-sans
  size-4
  align-middle
  inline-block
  ml-1
`)

type SpinnerProps = {
  className?: string
}

export default function Spinner(props: SpinnerProps) {
  const {
    className,
  } = props

  return (
    <Wrapper className={classNames(styles.spinner, className)} style={{ contain: 'strict' }} role='status' aria-live='polite' aria-label='Loading…'>
      <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 122.88 122.75'>
        <path d='M44.63,0H78.25a4,4,0,0,1,4,4V40.57h36.64a4,4,0,0,1,4,4V78.18a4,4,0,0,1-4,4H82.24v36.58a4,4,0,0,1-4,4H44.63a4,4,0,0,1-4-4V82.18H4a4,4,0,0,1-4-4V44.56a4,4,0,0,1,4-4H40.63V4a4,4,0,0,1,4-4Z' />
      </svg>
    </Wrapper>
  )
}